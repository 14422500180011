import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {  DeleteSurveyLibraryV2, GetSurveyLibraryDetails } from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { HeaderWrapper, OverlayDashboard } from "../../HealthTabV2/styles";
import { MainEventsContainer } from "../../MainEvents/styles";
import {connect} from "react-redux";
import { QuestionCard, SurveyPreviewHeader, SurveyPreviewStyle } from "./style";
import HoverButton from "../../common/HoverButton";
import { DeleteIcon, closeIcon, editIcon, scaleIcon } from "../../../utils/icons";
import CommonButton from "../../common/CommonButton/CommonButton";
import { Border } from "../../EventDetails/styles";
import DeleteSurveyPopUp from "./DeleteSurveyPopUp";
import Spinner from "../../common/Spinner";
import { fetchApi } from "../../../utils/methods";
import parse from 'react-html-parser';
import SkeletonLoder from "../../common/skeletonLoder";


const SurveyPreview = (props) => {
//   const { history, getSurveyDetails, userPermissions, match } = props;
  const { match, history,role } = props;
  const [SurveyPreview, setSurveyPreview] = useState({questions:[]});

  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const componentMounted = useRef(true); // (3) compo
  const[isReady, setIsReady]= useState(false);

  //   const createCustomSurvey = getPermissionStatus(
  //     "Create custom survey",
  //     userPermissions
  //   );

  useEffect(() => {

    // const editData = this.props.history.location.state;
    getSurveyDetails(match?.params?.id, history.location?.state?.companyId);

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  const UseTemplate = async (surveyId) => {
    setIsReady(true);
    history.push({ pathname: "/company/surveys/launch-custom-survey", state: { surveyId } });
  };


  const getSurveyDetails = async (surveyLibraryId) => {
    setIsLoading(true);
    let apiUrl = GetSurveyLibraryDetails + `/${surveyLibraryId}`;
    try {
      const res = await fetchApi(apiUrl , "GET");
      window.console.log("res",res)
      if (res?.status === "success") {
        setSurveyPreview(res.data);
        setIsLoading(false);

      } else {
        toast.error(res?.error);
        setIsLoading(false);
      }
    } catch (error) {
      window.console.log(error);
      setIsLoading(false);
    }

  }
  

  const DeleteSurvey = async (surveyId) => {
    setIsReady(true);
    try {
      const res = await fetchApi(`${DeleteSurveyLibraryV2}${surveyId}`, "DELETE");
      if (res.status === "failed") {
        toast.error(res?.data?.message);
      } else if (res.status === "success") {
        setIsDeletePopup(false);
        setIsReady(false);
        history.goBack();
      }
    } catch (error) {
      window.console.error(error);
      setIsReady(false);
    } finally {
      setIsReady(false);
    }
  };


  const Cards = (data)=>
    (
      <React.Fragment>
        {data && data?.length > 0 ? (
          data?.map((que, index) => (

            <QuestionCard key={index} style={{background:"white"}}>
              <div className="question-row">
                <div><span className="title">Q{index+1}. {que?.question}</span></div>
                <div><span className="type">{que?.question_type_name?.replace(/-/g, " ")}</span></div>
              </div>
              <Border style={{width:"100%", margin:"15px 0px 0px 0px", background:"rgba(0, 92, 135, 0.30)"}}/>

              <div className="ans">
                {que?.question_type_name === "likert-scale" ? 
                  <React.Fragment>
                    <div className="likert-title">{que?.question_answers[0]?.answer}</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0px 25px 0px 25px",
                      }}
                    >
                      {scaleIcon()}</div>
                    <div className="likert-title">{que?.question_answers[4]?.answer}</div>

                  </React.Fragment> : que?.question_type_name === "opinion-scale"  ? 
                    <React.Fragment>
                      <div className="likert-title">&#40;{que?.question_answers[0]?.points}&#41; &#32;{que?.question_answers[0]?.answer}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 25px 0px 25px",
                        }}
                      >
                        {scaleIcon()}</div>
                      <div className="likert-title"> &#40;{que?.question_answers[10]?.points}&#41; &#32;{que?.question_answers[10]?.answer}</div>
  
                    </React.Fragment> : que?.question_type_name === "short-answer" ? 
                      <React.Fragment>
                        <div className="likert-title">{que?.question_answers[0]?.answer || "Please enter your Answer"}</div>
                      </React.Fragment> : que?.question_type_name === "multiple-choice" || que?.question_type_name === "yes-no" ? 
                        <React.Fragment>
                          <div className="multiple-choice">
                            {que?.question_answers?.map((option,index)=>(
                              <div key={index} className="option">{option?.answer}</div>
                            ))}
                          </div>
                        </React.Fragment> : null}

              </div>
            </QuestionCard>
           
          ))
        ) : (
          <div>No data found</div>
        )}
      </React.Fragment>
   
    )
  

  return (
    <React.Fragment>

      <React.Fragment>
        <OverlayDashboard>
          <MainEventsContainer marginTop="1">
            {isLoading ? (
              <SkeletonLoder width={"1246px"} height={"100vh"} />
            ) : (<HeaderWrapper padding={1} style={{ padding: "0px",margin:"0px",display:"flex",alignItems:"center",flexDirection:"column",marginBottom:"50px" }}>
              <SurveyPreviewStyle></SurveyPreviewStyle>
              <SurveyPreviewHeader>
                <div className="title-row">
                  <div >
                    <div><span className="category">{SurveyPreview?.title}</span></div>
                    <div><span className="company">by { SurveyPreview?.created_by_name}</span></div>
                  </div>
                  <div className="right-side">
                    {role === "ADMIN" && <div><HoverButton svgPath={editIcon()} title={"Edit Survey Library"} onClick={()=>            history.push({
                      pathname: `/company/surveys/edit-survey-library/${SurveyPreview?.id}`,
                    })}/></div>}
                    <div style={{marginLeft:"15px"}}><CommonButton disabled={isReady} onClick={()=>UseTemplate(SurveyPreview?.id)} btnType={"square"} title={isReady? <Spinner color={"white"}/> : "Use Template"} styles={{background:"#005C87", marginLeft:"15px", cursor:'pointer'}}/></div>
                    {role === "ADMIN" && <div><HoverButton svgPath={DeleteIcon()}  onClick={() =>setIsDeletePopup(true)}  title={"Delete Survey"}/></div>}
                    <HoverButton
                      onClick={() => history.goBack()}
                      svgPath={closeIcon()}
                      title={"Close"}
                    />{" "}
                  </div>
                </div>

                <div style={{marginTop:"15px"}}>
                  <span className="survey-title">{parse(SurveyPreview?.description)}</span>
                </div>

                <div style={{display:"flex", marginTop:"10px",alignItems:"center"}}>
                  <div className="rect"></div>
                  <span className="questions">{SurveyPreview?.library_questions?.length} { SurveyPreview?.library_questions?.length > 1 ? "Questions" : "Question"}</span>
                </div>
              </SurveyPreviewHeader>
              {Cards(SurveyPreview?.library_questions)} 

            </HeaderWrapper>      )}

          </MainEventsContainer>
          
        </OverlayDashboard>

        {isDeletePopup && <DeleteSurveyPopUp isLoading={isReady} showModal={isDeletePopup} onClose={()=>setIsDeletePopup(false)} onYesClick={()=>DeleteSurvey(SurveyPreview?.id)}/>}
      </React.Fragment>
    </React.Fragment>
  );
};

SurveyPreview.propTypes = {
  history: PropTypes.object,
  userPermissions: PropTypes.array,
  companyInfo:PropTypes.object,
  match:PropTypes.object,
  role:PropTypes.object
};

const mapStateToProps = (state) => ({
  userPermissions: state.profileData.userPermissions,
});


export default withRouter(
  connect(mapStateToProps, null)(SurveyPreview)
);
